import axios from "axios";
import queryString from "query-string";
import React, { useContext, useEffect } from "react";
import { API_ROOT } from "../../constants";
import { UserContext } from "../../components/Layout";
import { navigate } from "gatsby";

function Confirm(props) {
  const { setUser } = useContext(UserContext);
  const qs = queryString.parse(props.location.search);

  useEffect(() => {
    // api call to
    axios
      .post(API_ROOT + "users/confirm", {
        token: qs.token,
      })
      .then((data) => {
        console.log("🚀 ~ file: confirm.jsx:18 ~ .then ~ data:", data);
        setUser({ isLoading: false, ...data.data.user });
        navigate("/");
      })
      .catch((err) => {
        console.log("🚀 ~ file: confirm.jsx:19 ~ .then ~ err:", err);
      });
  }, [qs]);

  return <div>Confirm</div>;
}

export default Confirm;
